import SvgIcon from "../components/SvgIcon"

const IconSize = () => {
  return (
    <SvgIcon viewBox="0 0 16 16">
      <path
        d="M3.648 2.517l1.035-1.034c.104-.104.142-.258.1-.398-.043-.142-.16-.248-.304-.277l-4-.8C.346-.02.21.023.117.118c-.094.094-.135.23-.11.36l.8 4c.03.146.136.262.277.305.038.012.077.017.116.017.104 0 .207-.041.283-.117l1.034-1.034 3.317 3.317c.157.156.362.234.566.234.204 0 .41-.078.565-.234.313-.313.313-.82 0-1.132L3.648 2.517zm11.268 8.7c-.038-.012-.077-.017-.116-.017-.104 0-.207.041-.283.117l-1.034 1.034-3.318-3.317c-.156-.156-.36-.234-.565-.234-.204 0-.41.078-.566.234-.312.313-.312.82 0 1.131l3.318 3.318-1.035 1.034c-.104.104-.142.258-.1.398.043.142.16.248.304.277l4 .8c.133.027.268-.015.362-.11.094-.094.135-.23.11-.36l-.8-4c-.03-.146-.136-.262-.277-.305zM6.4 8.8c-.204 0-.41.078-.566.234l-3.317 3.317-1.034-1.034c-.076-.076-.179-.117-.283-.117-.039 0-.078.005-.116.017-.141.043-.248.16-.276.305l-.8 4c-.026.13.015.266.11.36.093.095.228.137.36.11l4-.8c.145-.03.262-.135.305-.277.042-.14.004-.294-.1-.398l-1.035-1.034 3.317-3.318c.313-.312.313-.818 0-1.131-.156-.156-.36-.234-.565-.234zm2.634-1.834c-.312-.313-.312-.82 0-1.132l3.318-3.317-1.035-1.034c-.104-.104-.142-.258-.1-.398.043-.142.16-.248.304-.277l4-.8c.133-.027.268.015.362.11.094.094.135.23.11.36l-.8 4c-.03.146-.136.262-.277.305-.038.012-.077.017-.116.017-.104 0-.207-.041-.283-.117l-1.034-1.034-3.318 3.317c-.156.156-.36.234-.565.234-.204 0-.41-.078-.566-.234z"
        transform="translate(-162 -514) translate(162 512) translate(0 2)"
      />
    </SvgIcon>
  )
}

export default IconSize
