import SvgIcon from "../components/SvgIcon"

const IconTwitter = () => {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path d="M21.462 6.269c-.69.307-1.423.51-2.173.6a3.807 3.807 0 001.657-2.096 7.544 7.544 0 01-2.403.923C17.372 4.446 15.51 4.14 14 4.948s-2.29 2.53-1.904 4.2a10.718 10.718 0 01-7.79-3.96 3.8 3.8 0 001.17 5.059 3.773 3.773 0 01-1.713-.471v.046a3.791 3.791 0 003.03 3.692 3.789 3.789 0 01-1.704.064 3.784 3.784 0 003.527 2.63 7.56 7.56 0 01-4.696 1.625c-.308 0-.615-.018-.921-.055a10.724 10.724 0 0010.979.404 10.774 10.774 0 005.595-9.477v-.489a7.697 7.697 0 001.888-1.947z" />
    </SvgIcon>
  )
}

export default IconTwitter
