import SvgIcon from "../components/SvgIcon"

const IconPhoto = () => {
  return (
    <SvgIcon viewBox="0 0 1024 1024">
      <path d="M605.717 170.667c91.947 0 131.989 10.88 170.24 31.36 38.229 20.437 68.267 50.453 88.704 88.683 20.437 38.251 31.339 78.293 31.339 170.24v102.101c0 91.947-10.88 131.989-31.36 170.24-20.437 38.229-50.453 68.267-88.683 88.704-38.251 20.437-78.293 31.339-170.24 31.339h-187.435c-91.947 0-131.989-10.88-170.24-31.36-38.229-20.437-68.267-50.453-88.704-88.683-20.437-38.251-31.339-78.293-31.339-170.24v-102.101c0-91.947 10.88-131.989 31.36-170.24 20.437-38.229 50.453-68.267 88.683-88.704 38.251-20.437 78.293-31.339 170.24-31.339h187.435zM512 341.333c-94.251 0-170.667 76.416-170.667 170.667s76.416 170.667 170.667 170.667 170.667-76.416 170.667-170.667-76.416-170.667-170.667-170.667zM736 277.333c-29.44 0-53.333 23.893-53.333 53.333s23.893 53.333 53.333 53.333 53.333-23.893 53.333-53.333-23.893-53.333-53.333-53.333z"></path>
    </SvgIcon>
  )
}

export default IconPhoto
