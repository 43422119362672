import SvgIcon from "../components/SvgIcon"

const IconShare = () => {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path d="M15,3.2453624 C15.2470418,3.2453624 15.48534,3.33680602 15.6689647,3.50206826 L23.1741176,10.2567059 C23.5846276,10.6261649 23.617906,11.2584547 23.248447,11.6689647 C23.2249908,11.6950272 23.2001801,11.7198379 23.1741176,11.7432941 L15.6689647,18.4979317 C15.2584547,18.8673908 14.6261649,18.8341123 14.2567059,18.4236023 C14.0914436,18.2399776 14,18.0016794 14,17.7546376 L13.999412,14.9993624 L10.3011626,15 C7.60194593,15 5.06888784,16.303557 3.5,18.5 L3.33616593,18.7293677 C3.01515694,19.1787803 2.39060686,19.282872 1.94119427,18.961863 C1.64001281,18.7467334 1.48228773,18.382842 1.53120456,18.0159658 L2,14.5 C2.57246514,10.2065114 6.23488819,7 10.566373,7 L13.999412,6.9993624 L14,4.2453624 C14,3.69307765 14.4477153,3.2453624 15,3.2453624 Z"></path>
    </SvgIcon>
  )
}

export default IconShare
