import SvgIcon from "../components/SvgIcon"

const IconMaskA = () => {
  return (
    <SvgIcon viewBox="0 0 36 36">
      <path
        d="M33.992 16.837v-2.958h-3.369V11h-4.259v.957c0 .693-.558 1.26-1.255 1.26H10.875c-.69 0-1.254-.56-1.254-1.26V11H5.36v2.879H2v2.958h3.369v1.042H2v2.958h3.477C6.074 24.33 9.11 27 12.75 27c2.053 0 3.911-.848 5.251-2.21 1.34 1.362 3.198 2.21 5.25 2.21 3.64 0 6.676-2.67 7.273-6.163H34v-2.958h-3.369v-1.042h3.361zM12.75 22.712c-1.719 0-3.12-1.408-3.12-3.136v-2.078h6.234v2.078c0 1.735-1.394 3.136-3.114 3.136zm13.615-3.128c0 1.727-1.402 3.136-3.121 3.136-1.72 0-3.121-1.409-3.121-3.136v-2.086h6.234v2.086h.008z"
        transform="translate(-306 -442) translate(306 442)"
      />
    </SvgIcon>
  )
}

export default IconMaskA
