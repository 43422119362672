import SvgIcon from "../components/SvgIcon"

const IconAngle = () => {
  return (
    <SvgIcon viewBox="0 0 16 16">
      <path
        d="M5.27 8c0 1.845.842 2.667 2.73 2.667S10.73 9.845 10.73 8c0-1.845-.842-2.667-2.73-2.667S5.27 6.155 5.27 8m-2.727 2.746c-.896-1.7-.919-3.659-.062-5.376 1.116-2.238 3.571-3.58 6.11-3.345.489.047.958.165 1.414.32l-.418.854c-.053.11-.042.239.03.338.065.088.169.139.278.139.013 0 .026 0 .039-.002l3.197-.356c.117-.013.219-.084.27-.188.05-.103.043-.225-.02-.323L11.65.155c-.066-.102-.194-.162-.308-.154-.124.008-.234.08-.287.19l-.455.932C10 .906 9.372.76 8.72.697 5.62.407 2.62 2.05 1.254 4.785.222 6.854.251 9.31 1.331 11.358c.12.23.36.361.607.361.105 0 .212-.024.312-.074.335-.17.467-.571.293-.899m12.203.47c-1.26 2.525-3.917 4.12-6.76 4.12-.235 0-.47-.011-.707-.033-.651-.063-1.28-.21-1.88-.426l-.455.933c-.053.11-.163.181-.287.19h-.02c-.117 0-.226-.058-.289-.155L2.62 13.193c-.064-.097-.071-.22-.02-.323.05-.104.152-.175.269-.188l3.197-.356c.124-.015.245.039.317.137.072.1.083.228.03.338l-.418.855c.456.154.925.272 1.415.319 2.54.235 4.993-1.107 6.109-3.345.857-1.717.834-3.677-.062-5.376-.174-.328-.042-.73.293-.899.335-.17.746-.04.92.287 1.08 2.047 1.108 4.504.076 6.573"
        transform="translate(-429 -514) translate(429 512) translate(0 2)"
      />
    </SvgIcon>
  )
}

export default IconAngle
